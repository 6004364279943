<template>
  <component :is="item === undefined ? 'div' : 'b-card'">
    <b-overlay
      :show="item === null"
      rounded="sm"
    >
      <div
        v-if="item"
        class="mt-2 pt-75"
      >
        <b-form>
          <b-tabs pills>
            <b-tab
              :title="$t('administration.products.tabs.data.label')"
            >
              <b-row>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.title.label')"
                    label-for="title"
                  >
                    <b-form-input
                      id="title"
                      v-model="item.title"
                      :state="errors && errors.title ? false : null"
                    />

                    <b-form-invalid-feedback v-if="errors && errors.title">
                      {{ errors.title[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.slug.label')"
                    label-for="slug"
                  >
                    <b-form-input
                      id="slug"
                      v-model="item.slug"
                      :state="errors && errors.slug ? false : null"
                    />

                    <b-form-invalid-feedback v-if="errors && errors.slug">
                      {{ errors.slug[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.status.label')"
                    label-for="status"
                    :state="errors && errors.status ? false : null"
                  >
                    <v-select
                      id="status"
                      v-model="item.status"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="statusOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                      input-id="status"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.status">
                      {{ errors.status[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                  class="hidden"
                >
                  <b-form-group
                    :label="$t('form.role.label')"
                    label-for="role_id"
                    :state="errors && errors.role_id ? false : null"
                  >
                    <v-select
                      id="role_id"
                      v-model="item.role_id"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="rolesOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                      input-id="role_id"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.role_id">
                      {{ errors.role_id[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-tab>

            <b-tab
              :title="$t('general.seo_template_types.filter')"
            >
              <b-row>
                <b-col cols="12">
                  <h4>{{ $t('form.categories.label') }}</h4>
                  <hr>
                </b-col>

                <div class="repeater-form col-12">
                  <b-row
                    v-for="(category, index) in item.categories"
                    :id="'category' + index"
                    :key="index"
                    ref="row"
                  >
                    <b-col
                      cols="12"
                      md="4"
                    >
                      <b-form-group
                        :label="$t('administration.table.fields.category')"
                        :label-for="'categories.' + index + '.category_id'"
                        :state="errors && errors['categories.' + index + '.category_id'] ? false : null"
                      >
                        <v-select
                          :id="'categories.' + index + '.category_id'"
                          v-model="category.category_id"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="categoriesOptions"
                          :reduce="val => val.value"
                          :clearable="false"
                          :input-id="'categories.' + index + '.category_id'"
                        />
                        <b-form-invalid-feedback v-if="errors && errors['categories.' + index + '.category_id']">
                          {{ errors['categories.' + index + '.category_id'][0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                    <!-- Remove Button -->
                    <b-col
                      lg="2"
                      md="3"
                      class="mb-50"
                    >
                      <b-button
                        v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                        variant="outline-danger"
                        class="mt-0 mt-md-2"
                        @click="removeCategory(index)"
                      >
                        <feather-icon
                          icon="XIcon"
                          class="mr-25"
                        />
                        <span>{{ $t('general.delete') }}</span>
                      </b-button>
                    </b-col>
                    <b-col cols="12">
                      <hr>
                    </b-col>
                  </b-row>
                </div>

                <b-col cols="12">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    @click="addCategory"
                  >
                    <feather-icon
                      icon="PlusIcon"
                      class="mr-25"
                    />
                    <span>{{ $t('general.add') }}</span>
                  </b-button>
                </b-col>
                <b-row
                  v-if="errors && errors.categories"
                >
                  <b-col cols="12">
                    <b-form-invalid-feedback :state="false">
                      {{ errors.categories[0] }}
                    </b-form-invalid-feedback>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12">
                    <hr>
                  </b-col>
                </b-row>
              </b-row>
              <br>
              <br>

              <b-row>
                <b-col cols="12">
                  <h4>{{ $t('administration.products.tabs.attributes.label') }}</h4>
                  <hr>
                </b-col>

                <div class="repeater-form col-12">
                  <b-row
                    v-for="(attribute, index) in item.attributes"
                    :id="'attribute' + index"
                    :key="index"
                    ref="row"
                  >
                    <b-col
                      cols="12"
                      md="4"
                    >
                      <b-form-group
                        :label="$t('form.attribute_id.label')"
                        :label-for="'attributes.' + index + '.attribute_id'"
                        :state="errors && errors['attributes.' + index + '.attribute_id'] ? false : null"
                      >
                        <v-select
                          :id="'attributes.' + index + '.attribute_id'"
                          v-model="attribute.attribute_id"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="attributesOptions"
                          :reduce="val => val.value"
                          :clearable="false"
                          :input-id="'attributes.' + index + '.attribute_id'"
                          @input="loadAttributeValue($event, index)"
                        />
                        <b-form-invalid-feedback v-if="errors && errors['attributes.' + index + '.attribute_id']">
                          {{ errors['attributes.' + index + '.attribute_id'][0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="12"
                      md="4"
                    >
                      <b-form-group
                        :label="$t('form.attribute_value_id.label')"
                        :label-for="'attributes.' + index + '.attribute_value_id'"
                        :state="errors && errors['attributes.' + index + '.attribute_value_id'] ? false : null"
                      >
                        <v-select
                          :id="'attributes.' + index + '.attribute_value_id'"
                          v-model="attribute.attribute_value_id"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="attributeValuesOptions[`attribute_${attribute.attribute_id}`]"
                          :disabled="!attributeValuesOptions[`attribute_${attribute.attribute_id}`]"
                          :reduce="val => val.value"
                          :clearable="false"
                          :input-id="'attributes.' + index + '.attribute_value_id'"
                          :taggable="true"
                          :create-option="val => ({ value: `new_${val}`, label: val })"
                        />
                        <b-form-invalid-feedback v-if="errors && errors['attributes.' + index + '.attribute_value_id']">
                          {{ errors['attributes.' + index + '.attribute_value_id'][0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                    <!-- Remove Button -->
                    <b-col
                      lg="2"
                      md="3"
                      class="mb-50"
                    >
                      <b-button
                        v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                        variant="outline-danger"
                        class="mt-0 mt-md-2"
                        @click="removeAttribute(index)"
                      >
                        <feather-icon
                          icon="XIcon"
                          class="mr-25"
                        />
                        <span>{{ $t('general.delete') }}</span>
                      </b-button>
                    </b-col>
                    <b-col cols="12">
                      <hr>
                    </b-col>
                  </b-row>
                </div>

                <b-col cols="12">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    @click="addAttribute"
                  >
                    <feather-icon
                      icon="PlusIcon"
                      class="mr-25"
                    />
                    <span>{{ $t('general.add') }}</span>
                  </b-button>
                </b-col>
                <b-row
                  v-if="errors && errors.attributes"
                >
                  <b-col cols="12">
                    <b-form-invalid-feedback :state="false">
                      {{ errors.attributes[0] }}
                    </b-form-invalid-feedback>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12">
                    <hr>
                  </b-col>
                </b-row>
              </b-row>
              <br>
              <br>
            </b-tab>

            <b-tab
              :title="$t('form.template.label')"
            >
              <b-row>
                <b-col
                  cols="12"
                  md="12"
                >
                  <b-form-group
                    :label="$t('form.template.label')"
                    label-for="template"
                  >
                    <b-form-textarea
                      id="template"
                      v-model="item.template"
                      :state="errors && errors.template ? false : null"
                      :rows="`30`"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.template">
                      {{ errors.template[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
              <br>
              <br>
            </b-tab>
          </b-tabs>

          <!-- Action Buttons -->
          <b-button
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="onSubmit"
          >
            {{ $t('general.save') }}
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            variant="outline-secondary"
            :to="{ name: 'feeds-index' }"
          >
            {{ $t('general.cancel') }}
          </b-button>
        </b-form>
      </div>
    </b-overlay>
  </component>
</template>

<script>
import {
  BTab,
  BTabs,
  BCard,
  BButton,
  BRow,
  BCol,
  BMedia,
  BMediaBody,
  BMediaAside,
  BFormGroup,
  BFormInput,
  BForm,
  BTable,
  BFormInvalidFeedback,
  BImg,
  BOverlay,
  BFormTextarea,
  BLink,
  BFormFile,
} from 'bootstrap-vue'
import router from '@/router'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { mapGetters } from 'vuex'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import CategoryMixin from '@/mixins/CategoryMixin'
import StatusMixin from '@/mixins/StatusMixin'
import _ from 'lodash'

export default {
  directives: {
    Ripple,
  },
  components: {
    BTab,
    BTabs,
    BCard,
    BMedia,
    BMediaBody,
    BMediaAside,
    BLink,
    BFormFile,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BImg,
    BFormTextarea,
    BFormInvalidFeedback,
    vSelect,
    BOverlay,
    Treeselect,
  },
  mixins: [StatusMixin, CategoryMixin],
  data() {
    return {
      item: {
        status: 'enabled',
        title: null,
        template: null,
        slug: null,
        role_id: 2,
        categories: [],
        attributes: [],
      },
      rolesOptions: [],
      categoriesOptions: [],
      attributesOptions: [],
      attributeValuesOptions: [],
    }
  },
  computed: {
    ...mapGetters({
      errors: 'validation/errors',
    }),
  },
  beforeCreate() {
    this.$http.get('/api/roles', { params: { mode: 'client', per_page: 100 } })
      .then(response => {
        this.rolesOptions = this.$options.filters.transformForVSelect(response.data.data, 'id', 'title')
      })
  },
  created() {
    this.loadDataTab()
  },
  destroyed() {
    this.$store.dispatch('validation/clearErrors')
  },
  methods: {
    loadDataTab() {
      if (!Object.keys(this.categoriesOptions).length) {
        this.$http.get('/api/administration/fullcategories', {
          params: {
            type: 'product',
            status: 'enabled',
          },
        })
          .then(response => {
            // this.categoriesOptions = response.data
            this.categoriesOptions = this.$options.filters.transformForVSelect(response.data, 'id', 'title')
          })
      }

      if (!Object.keys(this.attributesOptions).length) {
        this.$http.get('/api/attributes', {
          params: {
            sort_by: 'title', sort_desc: false, status: 'enabled', per_page: 500,
          },
        })
          .then(response => {
            this.attributesOptions = this.$options.filters.transformForVSelect(response.data.data, 'id', 'title')
          })
      }
    },
    async loadAttributeValue(id, index) {
      this.item.attributes[index].attribute_value_id = null

      if (this.attributeValuesOptions && _.has(this.attributeValuesOptions, `attribute_${id}`)) {
        return
      }

      const response = await this.$http.get(`/api/attributes/${id}/values`)

      this.$set(this.attributeValuesOptions, `attribute_${id}`, await this.$options.filters.transformForVSelect(response.data.data, 'id', 'title'))
    },
    addAttribute() {
      this.item.attributes.push({
        attribute_id: null,
        attribute_value_id: null,
      })
    },
    removeAttribute(index) {
      this.item.attributes.splice(index, 1)
    },
    addCategory() {
      this.item.categories.push({
        category_id: null,
      })
    },
    removeCategory(index) {
      this.item.categories.splice(index, 1)
    },
    async onSubmit() {
      const data = {
        title: this.item.title,
        slug: this.item.slug,
        status: this.item.status,
        template: this.item.template,
        role_id: this.item.role_id,
        categories: this.item.categories,
        attributes: this.item.attributes,
      }

      this.$http.post('/api/administration/feeds', data)
        .then(() => {
          router.replace({ name: 'feeds-index' })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: error.response.data.message,
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
